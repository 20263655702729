<template>
  <div class="contaner_w_s">
    <div class="header_tab">
      <new-header :config="headerConfig"></new-header>
    </div>

    <div class="store_content">
      <div class="title1">{{ super_text }}分布</div>

      <div class="store_box">
        <div>
          <div>454896.00</div>
          <div class="text">氏客门店</div>
          <div class="bar"></div>
        </div>
        <div>
          <div>454896.00</div>
          <div class="text">企业实体</div>
        </div>
      </div>
    </div>

    <!-- 用戶須知 -->
    <div class="replacement_content">
      <div class="distribution" style="margin: 40px 0 30px 0">
        可用{{ super_text }}：<span>{{ contribution_balance }}</span><span class="order" v-if="radio == 2"
          @click="selectBtn">选择助力门店 ></span>
      </div>

      <div class="title1">请输入兑换工分</div>
      <input class="input_box plass_text" v-model="shareDate" @input="changeBtn" :disabled="contribution_balance == 0"
        placeholder="请输入需兑换的工分" />
      <div v-if="shareDatePiont" class="error_text">*消耗{{ shareDatePiont }}{{ super_text }}</div>
      <van-loading v-if="loadingShow" type="spinner" color="#1989fa" />
      <div class="submit_btn" @click="tabBtn">确认兑换</div>
    </div>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { Toast } from "vant";
// import customradio from "../../components/custom/customradio";
import {
  getSetting,
  getuser_api,
  getContributionExchangeShare_api,
  updateExchangeShare_api,
} from "@/api/common";
import * as apiCommon from '@/api/common';
export default {
  data() {
    return {
      headerConfig: {
        show: true,
        title: '',
      },
      show: false,
      shareDate: "",
      dataBtn: 1,
      contribution_balance: 0,
      radio: "1",
      selectModel: [], //选择专区
      shareDatePiont: "", //消耗
      loadingShow: false, //加载中
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      userFrom: {
        member: {},
      },
      settingDate: {},
      contribution: {},
      selectList: [],
      super_text: ''
    };
  },
  components: {
    newHeader,
    // customradio
  },
  created() {
    document.title = "";
    this.getUser();
    this.getConfig();
    this.getConfigOne()
    this.getContribution();
    if (localStorage.getItem("storeList")) {
      this.selectList = JSON.parse(localStorage.getItem("storeList"));
      if (this.selectList.length > 0) {
        this.radio = "2";
      } else {
        this.radio = "1";
      }
      this.contribution_balance = 0;
      this.selectList.forEach((e) => {
        this.contribution_balance =
          this.contribution_balance + Number(e.surplus_contribution_amount);
      });
    }
  },
  methods: {
    typeBtn(index) {
      console.log(index);
      let indexs = this.selectModel.findIndex((e) => {
        return e == index;
      });
      if (indexs == -1) {
        this.selectModel.push(index);
      } else {
        this.selectModel.splice(indexs, 1);
      }

      this.contribution_balance = 0;
      this.selectModel.forEach((e) => {
        this.contribution.tables.forEach((i) => {
          if (e == 1) {
            if (i.contribution_type == 1)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
          if (e == 2) {
            if (i.contribution_type == 2)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
          if (e == 3) {
            if (i.contribution_type == 3)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
          if (e == 4) {
            if (i.contribution_type == 4)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
        });
      });
    },
    getUser() {
      getuser_api().then((res) => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          this.$forceUpdate();
        }
      });
    },
    getConfig() {
      getSetting().then((res) => {
        console.log(res.status);
        if (res.code == 0) {
          this.settingDate = res.data;
          this.$forceUpdate();
        }
      });
    },
    // 获取文字设置
    async getConfigOne() {
      let data = {
        config: ["super_text"]
      }
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        this.super_text = res.data.super_text;
        this.headerConfig.title = `助力${this.super_text}选择`
      }
    },
    getContribution() {
      getContributionExchangeShare_api().then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.contribution = res.data;
        }
      });
    },

    //貢獻值結算
    changeBtn() {
      this.loadingShow = true;
      let data = {
        exchange_type: 1,
        exchange_share: Number(this.shareDate),
        super_list: [],
        exchange_module: [1, 2],
      };
      if (this.radio == 2) {
        this.selectList.forEach((e) => {
          data.super_list.push(e.id);
        });
      } else {
        delete data.super_list;
      }
      updateExchangeShare_api(data).then((res) => {
        if (res.code == 0) {
          this.loadingShow = false;
          this.shareDatePiont = res.data.contribution_amount;
        } else {
          Toast(res.error);
          this.loadingShow = false;
        }
      });
    },
    submitBtn() {
      this.show = true;
    },
    selectBtn() {
      this.$router.push(`./selectOrder`);
    },
    tabBtn() {
      if (!this.shareDate) {
        Toast("请输入兑换工分");
        return false;
      }
      this.sureBtn()
    },
    // 数据提交
    sureBtn() {
      let data = {
        exchange_type: 1,
        exchange_share: Number(this.shareDate),
        super_list: [],
        exchange_module: [1, 2],
      };
      if (this.radio == 2) {
        this.selectList.forEach((e) => {
          data.super_list.push(e.id);
        });
      } else {
        delete data.super_list;
      }
      let time_s =
        new Date().getTime() + 2592000000 >
        new Date(this.userFrom.union_shareholder_expire_at).getTime();
      // 不是股东或者股东身份还有三十天到期
      if (!this.userFrom.union_shareholder_info || time_s) {
        this.$router.push(`../workpoints/enterprise?type=1`);
      } else {
        this.$router.push(`../workpoints/enterprise?type=2`);
      }
      localStorage.setItem("workData", JSON.stringify(data));
    },
    cloneBtn() {
      this.show = false;
    },
  },
};
</script>
<style scoped lang="less">
.contaner_w_s {
  background-color: #f2f2f7;
}

.replacement_content {
  padding: 20px 38px;
  box-sizing: border-box;
  background-color: #fff;
}

.distribution {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  margin: 30px 0 20px 0;
}

.distribution_list {
  display: flex;
  flex-wrap: wrap;
}

.list_img {
  width: 320px;
  height: 170px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #1a1a1a;
  background-size: 100% 100%;
  text-align: center;
  padding-top: 44px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.radio_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #1a1a1a;
  margin: 37px 32px 0 87px;
}

.error_text {
  width: 602px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #fe3b22;
  margin: 32px 0 30px 20px;
}

.btn_list {
  display: flex;
}

.btn_list div {
  width: 140px;
  height: 60px;
  background: rgba(7, 139, 255, 0.1);
  border-radius: 4px 4px 4px 4px;
  /* border: 1px solid #078bff;/ */
  text-align: center;
  line-height: 60px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #078bff;
  margin-right: 20px;
}

.btn_list .btn0 {
  background: rgba(255, 70, 30, 0.1);
  color: #078bff;
  /* border: 1px solid #ff461e; */
}

.btn_list .btn1 {
  background: rgba(255, 70, 30, 0.1);
  color: #ff461e;
  /* border: 1px solid #ff461e; */
}

.btn_list .btn2 {
  background: rgba(20, 191, 132, 0.1);
  color: #14bf84;
  /* border: 1px solid #14bf84; */
}

.btn_list .btn3 {
  background: rgba(255, 128, 1, 0.1);
  color: #ff8001;
  /* border: 1px solid #ff8001; */
}

.selectBgc {
  border: 1px solid #000;
}

.distribution span {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #fe3b22;
}

.distribution .order {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #078bff;
  margin-left: 25px;
}

.title1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1a1a1a;
}

.input_box {
  width: 600px;
  height: 80px;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}

.plass_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  padding: 22px 0 22px 33px;
  box-sizing: border-box;
}

.submit_btn {
  width: 340px;
  height: 80px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  margin: 60px auto;
}

.cancel_box {
  width: 100%;
  height: 100%;
}

.cancel_box img {
  width: 100%;
  height: 100%;
}

.text_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.message_title {
  margin: 10px 0 50px;
}

.message_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: rgba(26, 26, 26, 0.8);
  width: 528px;
  margin: 31px auto 40px;
  text-align: left;
}

.sure_btn {
  width: 180px;
  height: 68px;
  background: linear-gradient(179deg, #ff6f51 0%, #ff0639 100%);
  border-radius: 34px 34px 34px 34px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  line-height: 68px;
  margin: 36px auto 31px;
}

.text1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 24px;
}

.rule_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #1a1a1a;
}

.rule_text span {
  color: #007dff;
}

.text_box img {
  width: 25px;
  height: 25px;
  margin-top: 52px;
  margin-left: 450px;
}

.store_content {
  .title1 {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 30px;
    color: #000000;
    margin-left: 50px;
  }
}

.store_box {
  width: 700px;
  height: 160px;
  background: #ffffff;
  border-radius: 15px 15px 15px 15px;
  margin: 20px auto;
  display: flex;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #1a1a1a;
  text-align: center;
  position: relative;

  >div {
    flex: 1;
    padding-top: 38px;
    box-sizing: border-box;

    .text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 26px;
      color: rgba(26, 26, 26, 0.6);
      margin-top: 10px;
    }

    .bar {
      width: 0px;
      height: 80px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 40px;
      left: 350px
    }
  }
}
</style>
