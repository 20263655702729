<style>
.select_box .van-checkbox__label {
  font-size: 25px !important;
}
</style>
<template>
  <div class="contaner_w">
    <new-header :config="headerConfig"></new-header>
    <div class="order_box">
      <van-tabs class="tabs_box" v-model="active" ref="tabs" @change="changeBtnType" style="width: 100%">
        <van-tab title="全部" name="0"></van-tab>
        <van-tab title="增值专区" name="4"></van-tab>
        <van-tab title="全返专区" name="3"></van-tab>
        <van-tab title="氏客专区" name="1"></van-tab>
        <van-tab title="企业实体" name="2"></van-tab>
      </van-tabs>
      <div class="tabs_content">
        <div class="select_box">
          <input v-model="searchOrder" placeholder="请输入订单账号" />
          <div class="search_btn" @click="searchBtn">查询</div>
          <van-checkbox v-model="checked" @change="changeBtn" icon-size="20px" shape="square"
            label-position="left">全选</van-checkbox>
        </div>
        <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished" style="padding-bottom: 100px">
          <van-checkbox-group v-model="result" ref="checkboxGroup" @change="checkboxBtn">
            <div class="list_item_s" v-for="(item, index) in dataList" :key="index">
              <div style="display: flex">
                <div class="bgc_list_icon" v-if="item.contribution_type == 2">
                  <div style="margin: 15px 0 5px 0">实体</div>
                  <div>助力</div>
                </div>
                <div class="bgc_list_icon1" v-if="item.contribution_type == 1">
                  <div style="margin: 15px 0 5px 0">氏客</div>
                  <div>门店</div>
                </div>
                <div class="bgc_list_icon2" v-if="item.contribution_type == 3">
                  <div style="margin: 15px 0 5px 0">全返</div>
                  <div>兑换</div>
                </div>
                <div class="bgc_list_icon3" v-if="item.contribution_type == 4">
                  <div style="margin: 15px 0 5px 0">增值</div>
                  <div>赠送</div>
                </div>
                <div class="text_order_text">
                  <div>点单编号：{{ item.order_sn }}</div>
                  <div>贡 献 值：{{ item.surplus_contribution_amount }}</div>
                  <div>下单时间：{{ item.created_at }}</div>
                </div>
              </div>
              <van-checkbox :name="item.id" shape="square"></van-checkbox>
            </div>
          </van-checkbox-group>
        </vue-loadmore>
        <van-empty v-if="dataList.length == 0" class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png" description="暂无数据" />
      </div>
      <!-- footerBtn -->
      <div class="footer_btn" v-if="selectList.length > 0">
        <div class="piont_box">
          <div>已选订单：{{ selectList.length }}</div>
          <div>{{ super_text }} ：{{ contribution }}</div>
        </div>
        <div class="sureBtn" @click="sureBtn">确认</div>
      </div>
    </div>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import * as apiCommon from "@/api/common";
import { Toast } from "vant";
export default {
  data() {
    return {
      headerConfig: {
        show: true,
        title: '订单选择',
      },
      active: "0",
      checked: false,
      result: [],
      queryList: {
        page: 1,
        limit: 10,
        type: "",
        search: ''
      },
      dataList: [],
      finished: false,
      selectList: [],
      contribution: 0,
      searchOrder: '',
      super_text: ''
    };
  },
  components: {
    newHeader,
  },
  mounted() {
    this.$refs.tabs.resize();
  },
  created() {
    document.title = "";
    this.getList();

  },
  methods: {
    // 获取文字设置
    async getConfigOne() {
      let data = {
        config: ["super_text"]
      }
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        this.super_text = res.data.super_text;
      }
    },
    submitBtn() {
      this.show = true;
    },
    searchBtn() {
      this.dataList = [];
      this.queryList.page = 1;
      this.queryList.search = this.searchOrder;
      this.getList()
    },
    // 确认选中
    sureBtn() {
      if (this.selectList.length == 0) {
        Toast("请选择订单");
        return false;
      }
      localStorage.setItem("selectList", JSON.stringify(this.selectList));
      this.$router.go(-1)
    },

    // 多选订单
    checkboxBtn(e) {
      // 选择的订单
      this.selectList = [];
      this.contribution = 0;
      this.dataList.forEach((item) => {
        e.forEach((i) => {
          if (item.id == i) {
            this.selectList.push(item);
            this.contribution =
              this.contribution + Number(item.surplus_contribution_amount);
          }
        });
      });
    },
    getList() {
      apiCommon.ContributionExchangeShareOrder_api(this.queryList).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.dataList = this.dataList.concat(res.data);
          this.pages = res.pagination.pages
        }
      });
    },
    changeBtn(e) {
      console.log(e);
      if (e) {
        this.$refs.checkboxGroup.toggleAll(true);
        this.selectList = this.dataList
      } else {
        this.$refs.checkboxGroup.toggleAll();
        this.selectList = []
      }

      console.log(e);
    },

    changeBtnType(e) {
      this.queryList.page = 1;
      this.dataList = [];
      if (e) {
        this.queryList.type = e;
      }
      this.getList();
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.dataList = [];
      this.queryList.page == 1;
      this.finished = false;
      this.fetch();
      done();
    },

    onLoad(done) {
      if (this.query.page >= this.pages) {
        this.finished = true;
      } else {
        this.fetch();
      }
      done();
    },

    fetch() {
      this.queryList.page++;
      this.getList();
    },
  },
};
</script>
<style>
.vuejs-refresh-head {
  height: 0 !important;
}
</style>
<style scoped>
.contaner_w {
  background-color: #f2f2f7;
  min-height: 100vh;
  margin-bottom: 100px;
  width: 100%;
}

.order_box {
  width: 100%;
}

van-tab {
  flex: 1;
}

.tabs_content {
  padding: 30px;
  box-sizing: border-box;
}

.select_box {
  display: flex;
  margin-top: 70px;
  z-index: 999;
}

.select_box input {
  width: 400px;
  height: 80px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  border: none;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 25px;
}

.search_btn {
  width: 120px;
  height: 80px;
  background: #078bff;
  border-radius: 12px 12px 12px 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  margin: 0 26px 0 10px;
}

/* van-checkbox{
    font-size: 20px;
} */
.select_order_list {}

.list_item_s {
  width: 700px;
  height: 180px;
  background: #ffffff;
  border-radius: 15px 15px 15px 15px;
  margin-top: 30px;
  padding: 20px 41px 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1a1a1a;
}

.bgc_list_icon {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #ff7700;
  width: 100px;
  height: 140px;
  background: #fff5ed;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ff7700;
  text-align: center;
  margin-right: 14px;
}

.bgc_list_icon1 {
  width: 100px;
  height: 140px;
  background: #eef9ee;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #4eb84a;
  color: #4eb84a;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  margin-right: 14px;
}

.bgc_list_icon2 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #ff461e;
  width: 100px;
  height: 140px;
  background: #fff7f5;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid#FF461E;
  text-align: center;
  margin-right: 14px;
}

.bgc_list_icon3 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #078bff;
  width: 100px;
  height: 140px;
  background: #eff8ff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #078bff;
  text-align: center;
  margin-right: 14px;
}

.text_order_text {
  padding-top: 5px;
  box-sizing: border-box;
}

.text_order_text div {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1a1a1a;
  margin-bottom: 22px;
}

.footer_btn {
  width: 750px;
  height: 240px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 34px 26px;
  box-sizing: border-box;
}

.piont_box {
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #1a1a1a;
}

.sureBtn {
  width: 700px;
  height: 100px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  line-height: 100px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin: 31px auto 0;
}

.tabs_box {
  position: fixed;
  top: 90px;
  left: 0;
  z-index: 999;
}
</style>
