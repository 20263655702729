<template>
    <div class="exchangeTime_page">
        <new-header :config="headerConfig"></new-header>

        <div class="top_div">
            <div class="top_1">
                <div class="image_div">
                    <van-image round fit="cover" class="image_avator" :src="user_avatar" />
                </div>
                <div class="text_div">
                    <div class="name_div">{{ userFrom.user_name }}</div>
                    <div class="time_div">会员有效期：{{ userFrom.union_expire_at }}到期</div>
                </div>
            </div>

            <div class="top_tip">
                当用户成为会员后，评价购物免费权益可兑换会员时长，仅有效订单方可兑换，兑换后订单关闭将扣除时长。
            </div>
        </div>
        <div class="color_box"></div>
        <div>
            <div class="list_search">
                <van-search v-model="searchValue" show-action placeholder="请输入订单号" @search="onSearch">
                    <template #action>
                        <van-button color="#0091FF" class="searchBtn" type="primary" @click="onSearch">搜索</van-button>
                    </template>
                </van-search>
            </div>
            <div class="list_tip" @click="goDetailPage">
                <div class="tip_left">*每笔订单只能兑换一次</div>
                <div class="tip_right">已兑换：{{ conversion_year }} <span>查看</span></div>
            </div>
            <div class="list_data">
                <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
                    <div class="card" v-for="(item, index) in list" :key="index">
                        <div class="card_img">
                            <img class="img" :src="item.goods_image" alt="">
                        </div>
                        <div class="card_text">
                            <div class="text_1">{{ item.goods_name }}</div>
                            <div class="text_2">订单编号：{{ item.order_sn }}</div>
                            <div class="text_2">时间：{{ item.add_time }}</div>
                            <div class="text_2">订单金额：{{ item.order_amount }}</div>
                        </div>
                        <div class="card_btn">
                            <van-button color="#0091FF" class="btn" type="primary"
                                @click="listChange(item)">兑换</van-button>
                        </div>
                    </div>
                    <van-empty v-if="emptyShow" :description="description" />
                </vue-loadmore>
            </div>
        </div>

        <new-dialog :config="dialogConfig" @confirm="dialogConfirmChange"></new-dialog>

    </div>
</template>
<script>
import newDialog from '@/components/newDialog.vue';
import newHeader from "@/components/newHeader";

import { getuser_api } from "@/api/common";
import { getConversionOrder_api, exchangeOrder_api, getOrderRead_api } from '@/api/perdata'

export default {
    components: {
        newDialog,
        newHeader
    },
    data() {
        return {
            headerConfig: {
                show: true,
                title: '兑换时长',
            },
            dialogConfig: {
                showDialog: false,
                text: '每笔订单仅可兑换一次，兑换后将自动延长一年时间，是否要兑换！',
                cancelText: '考虑考虑',
                confirmText: '立即兑换',
            },

            userFrom: {},
            user_avatar: '',
            conversion_year: '0年', // 已兑换时长

            searchValue: '',
            finished: false,
            pages: 0,
            query: { page: 1, limit: 10 },
            list: [],
            row: null,

            emptyShow: false,
            description: '暂无可兑换时长订单'
        }
    },
    created() {

        this.getUser()
        this.getList()
        this.getConversionTime()
    },
    methods: {
        // 获取会员信息
        async getUser() {
            let res = await getuser_api()
            if (res.code == 0) {
                this.userFrom = res.data
                this.user_avatar = res.data.user_avatar
            }
        },
        // 获取兑换时长
        async getConversionTime() {
            let res = await getOrderRead_api({ page: 1, limit: 1 })
            if (res.code == 0) {
                this.conversion_year = res.data.conversionYear
            }
        },
        // getOrderRead_api
        onRefresh(done) {
            this.list = [];
            this.query.page = 1;
            this.finished = false;
            this.getList();
            done();
        },
        onLoad(done) {
            if (this.query.page >= this.pages) {
                this.finished = true;
            } else {
                this.fetch();
            }
            done();
        },
        fetch() {
            this.query.page++;
            this.getList();
        },
        // 获取订单
        async getList() {
            let res = await getConversionOrder_api(this.query)
            if (res.code == 0) {
                res.data.forEach(item => {
                    item.goods_name = item.ordergoods.goods_name
                    item.order_amount = item.ordergoods.no_discount_price
                    item.goods_image = item.ordergoods.goods_image
                })
                this.list = this.list.concat(res.data)
                this.emptyShow = this.list.length == 0
                this.pages = res.pagination.pages
            } else {
                this.description = res.error
                this.emptyShow = true
            }
        },
        // 前往明细
        goDetailPage() {
            setTimeout(() => {
                this.$router.push("../newLogin/exchangeDetails")
            }, 500);
        },
        // 搜索
        onSearch() {
            this.query.search = this.searchValue
            this.list = []
            this.getList()
        },
        // 弹窗确认
        async dialogConfirmChange() {
            let res = await exchangeOrder_api({ order_id: [this.row.order_id] })
            if (res.code == 0) {
                setTimeout(() => {
                    this.dialogConfig.showDialog = false
                    this.$router.push("../newLogin/exchangeDetails")
                }, 500);
            }
        },
        listChange(row) {
            this.row = row
            this.dialogConfig.showDialog = true
        },
    }
}
</script>
<style scoped>
.exchangeTime_page {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
}

.top_div {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 35px;
    margin-bottom: 20px;
}

.top_1 {
    display: flex;
    align-items: center;
    height: 160px;
}

.top_tip {
    font-size: 24px;
    color: #FF4040;
    line-height: 32px;
}

.image_div {
    margin-right: 15px;
}

.image_avator {
    width: 100px;
    height: 100px;
}

.name_div {
    font-size: 32px;
    margin-bottom: 15px;
    font-weight: bold;
}

.time_div {
    font-size: 26px;
}

.color_box {
    height: 20px;
    background-color: #F9F9FB;
}

.list_search {
    padding: 0 10px;
    box-sizing: border-box;
}

.searchBtn {
    width: 120px;
    height: 66px;
    border-radius: 10px;
    font-size: 26px;
}

.van-search__action {
    line-height: normal;
}

/* 点击事件穿透 */
.vuejs-loadmore-wrap>>>.vuejs-refresh-head {
    pointer-events: none;
}

.list_tip {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 26px;
    box-sizing: border-box;
    padding: 0 35px;
    height: 60px;
    pointer-events: all;
}

.tip_left {
    color: #FF4040;
}

.tip_right>span {
    color: #0091FF;
    margin-left: 20px;
}

.list_data {
    padding: 0 35px;
}

.card {
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    background: linear-gradient(180deg, #D6EDFF 0%, #FFFFFF 50%);
    height: 200px;
    width: 100%;

    box-sizing: border-box;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
}

.card_img {
    margin-right: 15px;
    border-radius: 10px;
}

.img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.card_text {
    font-size: 26px;
    flex: 1;
}

.text_1 {
    font-weight: bold;
    width: 350px;
    color: #1A1A1A;
    margin-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text_2 {
    font-size: 24px;
    line-height: 39px;
    color: rgba(26, 26, 26, 0.8);
}

.card_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    width: 115px;
    height: 50px;
    border-radius: 10px;
    font-size: 24px;
}
</style>