<template>
  <div>
    <!-- 头部 -->
    <div class="header">
      <div class="header_info">
        <img class="header_img" src="../../assets/header.png" />
        <div style="height: 100%" class="header_user">
          <van-image round fit="cover" class="user_img" :src="userFrom.user_avatar" />
          <div class="user_info">
            <div class="userFromText">{{ userFrom.user_name }}</div>
            <div style="display: flex">
              <div class="vipImg1">
                <img style="width: 50px; height: 23px; margin: 5px 5px 0 0" src="../../assets/lador_leve.png" />
                <div class="vip_text1" v-if="userFrom.member">v{{ userFrom.member.vip_level }}</div>
              </div>
            </div>
          </div>
          <div class="info" @click="userGoPage(1)">个人资料></div>
        </div>
        <div class="userBtn" v-if="!userFrom.union_member" @click="userGoPage(2)">会员申请</div>

        <!-- 会员延期 -->
        <div class="text_info" v-else>
          <div class="status_info">
            <div>会员授权时间：{{ userFrom.union_expire_at }}</div>
            <div class="info_right" @click="userGoPage(3)">前往延期 ></div>
          </div>
          <div class="status_info" v-if="days < 31">
            <img src="../../assets/Vector@2x.png" />
            <div v-if="overTime == 0" class="info_left">会员身份还有{{ restTime }}天到期</div>
            <div v-else class="info_left">会员身份已逾期{{ overTime }}天，账号冻结请及时处理</div>
            <div @click="userGoPage(4)">去处理 ></div>
          </div>
        </div>

      </div>
    </div>

    <!-- 金刚区 -->
    <div class="lador_list">
      <div class="list_title">
        <div>
          <div style="font-size: 25px; color: #2e2e30">
            权益应用
            <span style="font-size: 10px">APPLICATION</span>
          </div>
          <div style="margin-top: 5px">贴心服务，助力美好生活</div>
        </div>
        <div class="phone_icon" @click="userGoPage(5)"><img src="../../assets/message.png" /> 联系客服</div>
      </div>
      <div class="listLog">
        <div v-for="(item, index) in iconList" :key="index">
          <div class="list_icon1">
            <div class="list_img1" @click="listBtn(index)">
              <img v-if="index == 0" src="../../assets/Group_39931@2x.png" />
              <img v-if="index == 1" src="../../assets/Group_39932@2x.png" />
              <img v-if="index == 2" src="../../assets/pay.png" />
              <img v-if="index == 3" src="../../assets/Slice42.png" />
              <img v-if="index == 4" src="../../assets/icon4.png" />
              <img v-if="index == 5" src="../../assets/Group_39936@2x.png" />
              <img v-if="index == 6" src="../../assets/icon6.png" />
              <img v-if="index == 7" src="../../assets/Group_39938@2x.png" />
              <img v-if="index == 8" src="../../assets/Group_39939@2x.png" />
              <!-- <img v-if="index == 9" src="../../assets/Slice10.png" /> -->
            </div>
            <div style="margin-top: 10px">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>

    <new-dialog :config="dialogConfig_1" @confirm="dialogConfirm_1"></new-dialog>

    <!-- 提示框 -->
    <van-dialog v-model="show" width="85%" :show-confirm-button="false">
      <div class="cancel_box">
        <img src="https://cdn.health.healthplatform.xyz/Fjmn3KOW5Zax17HdrGyhlwktZQOw" />
        <div class="text_box">
          <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
          <div class="message_title">温馨提示</div>
          <div class="message_text" v-if="userFrom.vipShare == 1 && userFrom.overdue != 1">
            尊敬的会员您好，您的销售合同即将到期，逾期后将无法进行工分置换，请仔细阅读与了解续签协议，如果同意请确认，在您置换过程中会同步完成续签，原有销售合同将失效。
          </div>
          <div class="message_text" v-if="userFrom.overdue == 1">
            尊敬的会员您好，您的销售合同已经到期，逾期后将无法进行工分置换，请仔细阅读与了解续签协议，如果同意请确认，在您置换过程中会同步完成续签，原有销售合同将失效。
          </div>
          <div class="sure_btn" @click="sureBtn">我知道了</div>
          <div class="text1" @click="sureBtnUser">
            单独续签
          </div>
          <div class="rule_text">
            《企业销售续签协议》
          </div>
        </div>
      </div>
    </van-dialog>

  </div>
</template>
<script>
import { getuser_api } from "@/api/common";
import Cookies from "js-cookie";
import newDialog from '@/components/newDialog.vue';

export default {
  components: {
    newDialog
  },
  data() {
    return {
      iconList: [
        { icon: "../../assets/icon2.png", text: "我的会员" },
        { icon: "../../assets/icon3.png", text: "电子证件" },
        { icon: "../../assets/icon3.png", text: "工分申请" },
        { icon: "../../assets/Slice42.png", text: "地址管理" },
        { icon: "../../assets/user.png", text: "电子合同" },
        { icon: "../../assets/pay.png", text: "工分明细" },
        { icon: "../../assets/shouyi.png", text: "物料中心" },
        { icon: "../../assets/duihuan.png", text: "物料变更" },
        { icon: "../../assets/Slice10.png", text: "服务站点" },
        // { icon: "../../assets/shouyi.png", text: "工分增值" },
      ],
      userFrom: {
        renew: {
          audit: {},
        },
        shareholder_renew: {
          audit: {},
        },
      },
      days: 0,

      show: false,

      dialogConfig_1: {
        showDialog: false,
        text: "您当前不是工分会员，是否申请兑换工分成为工分会员。",
        cancelText: '考虑考虑',
        confirmText: '工分申请',
      },

      overTime: 0,
      restTime: 0,
    };
  },
  created() {
    document.title = "个人中心";
    this.getUser();
  },
  methods: {

    userGoPage(val) {
      if (!Cookies.get("token")) return false
      if (val == 1) {
        this.$router.push("../personalData/mine"); //个人资料
      } else if (val == 2) {
        if (this.userFrom.member_id == 0) {
          this.$router.push("../lador/index"); // 绑定购购
        } else {
          this.$router.push("../newLogin/application"); // 兑换时长成为会员
        }
      } else if (val == 3) {
        if (this.userFrom.vipUser == 0 && this.userFrom.union_member_freeze == 1) return this.$toast("您的账号已被后台冻结，请联系管理员")
        this.$router.push("../newLogin/exchangeTime"); //会员延期
      } else if (val == 4) {
        if (this.userFrom.vipUser == 0 && this.userFrom.union_member_freeze == 1) return this.$toast("您的账号已被后台冻结，请联系管理员")
        this.$router.push("../newLogin/exchangeTime"); //会员延期
      } else if (val == 5) {
        // this.$router.push("../lador/service");  //跳转客服
        window.location.href = "https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000045676&chatId=176634cd-cb09-4d26-89e6-86a702354bcf";
      }
    },

    listBtn(index) {
      if (!Cookies.get("token")) return false
      // 判断当前是否为游客
      if (this.userFrom.member_id == 0) return this.$toast("您目前身份是游客，请先绑定购购账号")
      // 判断是否为会员
      if (this.userFrom.union_member == 0 && index != 0) return this.$toast('权限不足，请先申请成为会员')

      // 会员冻结不可进入
      if (index == 2 || index == 7 || index == 9) {
        if (this.userFrom.union_member_freeze == 1) {
          if (this.userFrom.vipUser == 1) return this.$toast("会员已冻结，请先前往延期")
          if (this.userFrom.vipUser == 0) return this.$toast("您的账号已被后台冻结，请联系管理员")
        }

        if (this.userFrom.member.shares_freeze == 1 && this.userFrom.overdue == 0) return this.$toast("您的工分会员已被后台冻结，请联系管理员")
      }
      // 成为工分会员才能进入的页面
      if (index == 4 || index == 5 || index == 6 || index == 7 || index == 9) {
        if (this.userFrom.union_shareholder == 0) return this.dialogConfig_1.showDialog = true
      }

      switch (index) {
        case 0:
          // 兑换时长
          if (this.userFrom.vipUser == 0 && this.userFrom.union_member_freeze == 1) return this.$toast("您的账号已被后台冻结，请联系管理员")
          if (this.userFrom.union_member == 0) return this.$router.push("../newLogin/application");
          this.$router.push('../newLogin/applicationSuccess') // 兑换成功
          break;
        case 1:
          this.$router.push("../document/index"); // 电子证件
          break;
        case 2:
          // 判断是否逾期需要续费 || 判断30天到期
          if (this.userFrom.overdue == 1 || this.userFrom.vipShare == 1) return this.show = true;
          this.$router.push("../workpoints/replacement");  // 工分申请
          break;
        case 3:
          this.$router.push("../address"); // 地址
          break;
        case 4:
          this.$router.push("../electronicContract/index"); // 电子合同
          break;
        case 5:
          this.$router.push("../deal/message"); // 工分明细
          break;
        case 6:
          localStorage.removeItem("selectIndex")
          this.$router.push("../alteration/applicationRecord"); // 物料中心
          break;
        case 7:
          this.$router.push("../alteration/index"); // 物料变更
          break;
        case 8:
          this.$router.push("../personalData/serveSite"); // 服务站点
          break;
        case 9:
          this.$router.push("../conversion/index"); // 工分增值
          break;
        default:
          break;
      }
    },

    // 确认前往工分会员申请
    dialogConfirm_1() {
      if (this.userFrom.union_member_freeze == 1) {
        if (this.userFrom.vipUser == 1) return this.$toast("会员已冻结，请先前往延期")
        if (this.userFrom.vipUser == 0) return this.$toast("您的账号已被后台冻结，请联系管理员")
      }
      this.$router.push("../workpoints/replacement");
    },

    cloneBtn() {
      this.show = false
    },
    // 单独续签
    sureBtnUser() {
      this.$router.push("../workpoints/enterprise?overdue=1")
    },
    // 工分申请,续签
    sureBtn() {
      this.$router.push("../workpoints/replacement")
    },

    getUser() {
      getuser_api().then((res) => {
        if (res.code == 0) {
          this.userFrom = res.data;

          if (!this.userFrom.renew) {
            this.userFrom.renew = {};
            this.userFrom.shareholder_renew = {};
          }

          if (this.userFrom.union_expire_at) {
            if (new Date().getTime() + 2592000000 > new Date(this.userFrom.union_expire_at).getTime()) {
              this.userFrom.vipUser = 1;
            } else {
              this.userFrom.vipUser = 0;
            }
          } else {
            this.userFrom.vipUser = 3;
          }

          // 判断是否超过30天
          if (this.userFrom.union_shareholder_expire_at) {
            if (new Date().getTime() + 2592000000 > new Date(this.userFrom.union_shareholder_expire_at).getTime()) {
              this.userFrom.vipShare = 1;
            } else {
              this.userFrom.vipShare = 0;
            }
          } else {
            this.userFrom.vipShare = 3;
          }

          // 判断工分是否逾期
          if (this.userFrom.union_shareholder_expire_at) {
            if (new Date().getTime() > new Date(this.userFrom.union_shareholder_expire_at).getTime()) {
              this.userFrom.overdue = 1;
            } else {
              this.userFrom.overdue = 0;
            }
          } else {
            this.userFrom.overdue = 3;
          }

          this.days = (new Date(this.userFrom.union_expire_at).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000);
          this.days = Math.floor(this.days);

          this.overTime = this.userFrom.renew ? (Math.abs(this.userFrom.renew.over)).toFixed(2) : 0
          this.restTime = this.userFrom.renew ? (this.userFrom.renew.over).toFixed(2) : 0

        }
      });
    },

  },
};
</script>
<style scoped>
.header {
  width: 100%;
  height: 3.75rem;
  background: #2e2e30;
  border-radius: 0 0 0.3rem 0.3rem;
  position: relative;
}

.header_info {
  width: 726px;
  height: 394px;
  border: 3px solid #f5e9d6;
  background: linear-gradient(89deg, #f2d39d 0%, #dab274 100%);
  opacity: 1;
  border-radius: 34px;
  position: absolute;
  left: 9px;
  top: 100px;
  padding: 36px 45px;
  box-sizing: border-box;
  position: relative;
}

.header_img {
  width: 718px;
  height: 264.39px;
  position: absolute;
  top: 49px;
  left: 0;
}

.header_user {
  position: absolute;
  top: 36px;
  left: 45px;
  display: flex;
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #563b15;
  opacity: 1;
  z-index: 2;
}

.info {
  color: #563b15;
  font-size: 25px;
  position: absolute;
  top: 0px;
  right: -300px;
}

.user_img {
  width: 96px;
  height: 96px;
  border: 3px solid #ffffff;
  border-radius: 50%;
  opacity: 1;
}

.lador_icon {
  width: 46px;
  height: 46px;
  margin-top: 13px;
  margin-right: 15px;
}

.user_info {
  margin-left: 20px;
}

.lador_list {
  padding-left: 20px;
}

.list_title {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(46, 46, 48, 0.5);
  opacity: 1;
  margin: 250px 46px 20px 19px;
  display: flex;
  justify-content: space-between;
}

.listLog {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #909090;
  margin-bottom: 30px;
}

.list_img1 {
  border-radius: 50%;
  margin-bottom: 30px;
}

.list_img1 img {
  width: 95px;
  height: 95px;
}

.list_icon1 {
  width: 130px;
  text-align: center;
  margin-top: 20px;
  margin: 10px 20px;
}

.vipImg1 {
  position: relative;
}

.vip_text1 {
  position: absolute;
  left: 48px;
  top: 22px;
  font-size: 18px;
  color: #d4b479;
}

.userFromText {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.phone_icon {
  font-size: 20px;
  color: #000000;
  margin-top: 30px;
}

.phone_icon img {
  width: 28px;
  vertical-align: middle;
}

.status_info {
  display: flex;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #563b15;
  margin-top: 26px;
}

.status_info img {
  width: 26px;
  height: 24px;
  margin-right: 10px;
}

.text_info {
  margin-top: 170px;
  position: relative;
  z-index: 999;
}

.info_right {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ff0d0d;
  margin-left: 53px;
}

.info_left {
  width: 455px;
  margin-right: 25px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ff0d0d;
}

.userBtn {
  width: 190px;
  height: 74px;
  background: linear-gradient(180deg, #252324 0%, #3c3633 100%);
  border-radius: 100px 100px 100px 100px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #f9e3be;
  text-align: center;
  line-height: 74px;
  position: absolute;
  bottom: 141px;
  right: 40px;
}

.cancel_box {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.cancel_box img {
  width: 100%;
  height: 100%;
}

.text_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.message_title {
  margin: 10px 0 50px;
}

.message_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: rgba(26, 26, 26, 0.8);
  width: 528px;
  margin: 31px auto 40px;
  text-align: left;
}

.sure_btn {
  width: 180px;
  height: 68px;
  background: linear-gradient(179deg, #ff6f51 0%, #ff0639 100%);
  border-radius: 34px 34px 34px 34px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  line-height: 68px;
  margin: 36px auto 31px;
}

.text1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 24px;
}

.rule_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #1a1a1a;
}

.rule_text span {
  color: #007dff;
}

.text_box img {
  width: 25px;
  height: 25px;
  margin-top: 52px;
  margin-left: 450px;
}

.progress_box {
  padding: 50px 30px;
  box-sizing: border-box;
  position: relative;

  .left_price {
    left: 20px;
    position: absolute;
    top: 3px;
  }

  .right_rate {
    right: 20px;
    top: 3px;
    position: absolute;
  }
}
</style>
