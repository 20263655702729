<template>
  <div class="infomation_box">
    <div class="from_box" style="
        overflow-y: scroll;
        position: relative;
        background: #fff;
        padding: 0;
      ">
      <div id="pdfDom" v-watermark="{ text: '电子合同，打印无效',font:'20PX Nsimsun',id:'pdfDom', }" ref="pdfDom">
        <div class="out-wrap">
          <div class="page" ref="page">
            <div>
              <p class="bold">编号:{{ shareDate.contractCode }}</p>
            </div>
            <img src="https://cdn.health.healthplatform.xyz/FuCrof-OysFo98LHmOPx08OoNrsx" style="width: 60%; margin: 0 auto; display: block" />
            <div class="cover2">
              <p>分</p>
              <p>红</p>
              <p>权</p>
              <p>益</p>
              <p>赠</p>
              <p>予</p>
              <p>合</p>
              <p>同</p>
            </div>
            <div class="partitem">
              <div class="cover-data">
                <span class="titles">姓名：</span>
                <span class="text">{{ shareDate.name }}</span>
              </div>
              <p class="cover-data item">
                签订日期： <span class="word-empty">{{ dataTime.split("-")[0] }}</span>年 <span class="word-empty">{{ dataTime.month }}{{ dataTime.split("-")[1] }}</span>月 <span class="word-empty">{{ dataTime.split("-")[2] }}</span>日
              </p>
            </div>
          </div>
          <div class="page">
            <img src="https://cdn.health.healthplatform.xyz/FuCrof-OysFo98LHmOPx08OoNrsx" style="width: 60%; margin: 10PX auto 0; display: block" />
            <div class="title-text">分红权益赠予合同</div>
            <div class="titles-text">总则</div>
            <div class="part">
              <p class="zindex">
                《企业工会委员会会员分红权赠予制度》是会员享受公司收益权的一种实现形式，是企业创始所有者与会员分享收益权的一种制度规定。推行会员分红权赠予目的是使得更多的会员享有公司发展带来的收益。本合同是将《企业工会委员会会员分红权赠予制度》这一制度落实的一部分。
              </p>
              <p class="zindex">
                会员享有的分红权均是通过自愿按本制度相应规定获得的企业部分分红权益。企业工会会员要获得企业工会的分红权必须是企业工会会员，且必须是公司健德购购商城会员。通过在健德购购商城消费推广、参加免费活动获赠补金等贡献值，因会员获得一定比例贡献值故而可以获得企业工会会员分红权益赠予的机会，既而获得相应的发展红利，仅限于财务回报。
              </p>
              <p class="zindex item">
                本合同是企业工会会员与“工会法人资格组织”——湖北利道投资控股股份有限公司工会委员会的分红权益赠予合同。以下名列的甲、乙双方经过充分的友好协商，为了保护甲、乙双方的合法权益，维护社会的经济秩序，弘扬社会主义核心价值观，根据《中华人民共和国宪法》、《中华人民共和国民法典》、《中华人民共和国公司法》、《中华人民共和国工会法》等法律、法规的要求，以及湖北利道投资控股股份有限公司《公司章程》的规定。甲、乙方双方本着平等、自愿、公平、诚信、守法、公序良俗的原则，签署了本合同。
              </p>
            </div>
          </div>

          <div class="page item" style="margin-top:30PX">
            <div class="data mt40">
              <span class="titles bold">甲方（受赠方）：</span>
              <span class="text">{{ shareDate.name }}</span>
            </div>
            <div class="data">
              <span class="titles bold">身份证号码：</span>
              <span class="text">{{ shareDate.Idcard }}</span>
            </div>
            <div class="data">
              <span class="titles bold">收件地址：</span>
              <span class="text">{{ shareDate.address }}</span>
            </div>
            <div class="data">
              <span class="titles bold">联系电话：</span>
              <span class="text">{{ shareDate.phone }}</span>
            </div>
            <div class="data mt70">
              <span class="titles bold">乙方（赠予方）：</span>
              <span class="text">湖北利道科技投资有限公司工会委员会</span>
            </div>
            <div class="data">
              <span class="titles bold">法定代表⼈：</span>
              <span class="text">黄紫娟</span>
            </div>
            <div class="data">
              <span class="titles bold">统一社会信用代码：</span>
              <span class="text">81420103MC60467913</span>
            </div>
            <div class="data">
              <span class="titles bold">住所地：</span>
              <span class="text">湖北省武汉市江岸区金桥大道115号长江传媒大厦主楼13层1305号</span>
            </div>
            <div class="data mb30">
              <span class="titles bold">联系电话：</span>
              <span class="text">156 2398 9912</span>
            </div>
            <div>
              <p class="zindex item mt70">
                第一条：乙方已经合法取得了湖北利道投资控股股份有限公司（以下简称“公司”）的股份，经公司董事会、股东大会表决通过，并征得公司其他股东的同意，乙方有权将其持有公司的30%股份部分或全部对应的股份分红权转让给乙方的任一会员，但该任一会员不可以是公司的独立自然人股东或任何性质的其它企业、机构、组织。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第二条：公司于2019年5月21日成立，是法人投资控股的股份有限公司，注册资本人民币三亿元整。公司的他方占有公司70%的股份，乙方占有公司30%的股份。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第三条：本合同的甲方已充分证明是乙方的会员，且系公司购购商城会员。因甲方自愿在购购商城消费推广获赠贡献值，经甲、乙双方协商就赠予企业工会在公司享有的股份对应的分红权益事宜，
                达成了本合同的约定内容。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第四条：公司注册资本3亿元，享有3亿股权数的分红权。乙方持有公司30%的股份，即享有公司9000万股权数的分红权。本协议中公司总分红权权数或乙方赠送给甲方的分红权权数均系根据本协议本条条款内容进行陈述。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第五条：经甲、乙双方协商一致，甲方自愿将其在购购商城消费推广获赠等持有的贡献值进行清零处理。乙方自愿将其持有公司<span class="shareCount">{{ shareDate.exchange_share }}</span>权数的分红权益赠送给甲方，该分红权可赠与或继承，甲方不承担乙方公司发展任何运营亏损，不可抗拒因素除外。
              </p>
            </div>
          </div>

          <div class="page item" style="margin-top:35PX">
            <div>
              <p class="zindexs">
                该分红权益虽由乙方赠送给甲方，但该分红权对应股份仍由乙方持有，除分红权益外其他的股东权利及义务仍由乙方享有和承担。如甲方丧失自然人权利或违反本合同约定或不再系乙方的会员时，该分红权将由乙方无偿收回，甲、乙双方无须再另行签订分红权益转让协议，甲、乙双方的损失由各方自行承担。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第六条：乙方保证对其拟赠予给甲方的分红权益拥有完全、有效的处分权。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第七条：本协议生效后，甲方对其获赠的公司分红权益经乙方同意，经合法程序备案后可对第三方进行转让，对此甲方清楚且自愿承担由此产生的一切法律责任及后果。
              </p>
            </div>
            <div>
              <p class="zindex item">
                如甲方无法偿还购购商城赊购专区或创业专区所欠货款，还款时间内甲方须用分红权益质押偿还货款。乙方有权在甲方未还清购购商城所欠货款前暂停办理甲方的分红权益转让手续，乙方有权配合公司直接采用甲方的分红权收益抵扣甲方的所欠货款，直至甲方还清所有货款之日。如甲方不再系健德购购商城会员，或甲方不再系乙方会员，或甲方购买的健德购购商城产品发生退货退款，或发生甲方向乙方或公司无理追索而产生纠纷时，发生以上任何一种情形，乙方均有权立即撤回本协议书中赠予给甲方的全部分红权益，且无须向甲方作出任何补偿或赔偿，对此甲方清楚且自愿承担由此产生的一切法律责任及后果。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第八条：如甲方不再是企业工会会员，乙方将无偿收回此前赠送给甲方的分红权益，甲方此前因受让赠送分红权益清零处理的购购赠送的补金等贡献值，将不再进行恢复，该损失由甲方自行承担。本合同所涉分红权益系无偿赠与，该利益并非无条件取得，须建立在企业经营盈利基础之上，故企业兴衰和经营成败与该项利益的实际取得息息相关，甲方对此清楚的知晓并认可。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第九条：如因履行本协议发生争议的，应先由甲、乙双方友好协商解决。如协商不成，任何一方均有权向乙方住所地的人民法院提起诉讼。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第十条：在转让过程中发生的与转让有关的费用(如公证、审计、按照平台交易规则需要支付的交易手续费、税费等)，如系因乙方单方提出，则由乙方承担。如系甲方提出，则由甲方承担。
              </p>
            </div>
            <div>
              <p class="zindex item">
                第十一条：本协议一律以电子版的形式签订并保存，经甲、乙双方签字或盖章生效。任何对本电子协议的打印行为所形成的非电子样式协议均无效,
                公司均不予认可。
              </p>
            </div>
          </div>

          <div class="page item" style="margin-top:70PX">
            <div>
              <div class="sign_data">
                <span class="titles bold">甲方（受赠予方签名）：</span>
                <img :src="shareDate.nameImg" alt="无" v-if="shareDate.nameImg" ref="signature"/>
              </div>
              <div class="data">
                <span class="titles bold">身份证号码：</span>
                <span class="text">{{ shareDate.idCode||shareDate.Idcard }}</span>
              </div>
              <div class="data">
                <span class="titles bold">乙方（赠予方）：</span>
                <span class="text">湖北利道科技投资有限公司工会委员会</span>
              </div>
              <div class="daibiao">
                <span class="titles bold">代表人（签名）：</span>
                <img src="./yuhongshan.png" />
                <img src="./committee.png" />
              </div>
              <div class="data datespan">
                签订日期： <span class="word-empty">{{ dataTime.split("-")[0] }}</span>年 <span class="word-empty">{{ dataTime.split("-")[1] }}</span>月 <span class="word-empty">{{ dataTime.split("-")[2] }}</span>日
              </div>
            </div>
          </div>
        </div>
      </div>

      

      <div class="neth_btn_e confim_btn" @click="confim">确认</div>
    </div>
  </div>
</template>
<script>
import html2Canvas from "html2canvas";

import JsPDF from "jspdf";
import { vipapply_api, shareapply_api } from "@/api/deal";
import { Toast } from "vant";

export default {
  data() {
    return {
      fileList: [],
    };
  },
  props: {
    nameImg: {
      type: String,
    },
    dataTime: {
      type: String,
    },
    contractCode: {
      type: String,
    },
    shareDate: {
      type: Object,
    },
    pdfToken: {
      type: String,
    },
    imgToken: {
      type: String,
    },
    share: {
      type: String,
    },
  },
  mounted() {
    // this.getquery(); 
    // this.getToken();
  },
  watch: {
    renewShow: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.timer = setInterval((e) => {
            console.log(e, oldValue);
            if (this.time == 0) {
              clearInterval(this.timer);
            } else {
              this.time--;
            }
          }, 1000);
          this.timer;
        }
      },
    },
  },
  methods: {
    cloneBtn() {
      console.log(this.renewShow);
      this.$emit("cloneBtn");
    },
    confim() {
      window.scrollTo(0, 0);
      this.cloneBtn1();
    },
    dataURLtoBlob(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    outPutPdfFn() {
      // $myLoading 自定义等待动画组件，实现导出事件的异步等待交互
      // dom的id。
      let pageHeight = 1137;
      const pdfDom = document.getElementById("pdfDom"); // 需要导出pdf的内容
      let lableListID = pdfDom.getElementsByTagName("div"); // 可能被截断的所有块元素;
      console.log(lableListID.length, pdfDom);
      // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
      for (let i = 0; i < lableListID.length; i++) {
        console.log(i, "i");
        // 获取分割dom，此处为class类名为item的dom
        let x1 = document.getElementById("item").offsetTop;
        let x2 = document.getElementById("itemss").offsetTop + x1;
        let multiple = Math.ceil(
          (lableListID[i].offsetTop + x2 + lableListID[i].offsetHeight) / pageHeight
        );
        let x = x2 - 21 * (multiple - 1); // 此处因为页面高度不准加的随机数
        // 判断是否该分页
        if (this.isSplit(lableListID, i, multiple * pageHeight)) {
          let divParent = lableListID[i].parentNode; // 获取该div的父节点
          let newNode = document.createElement("div");
          newNode.className = "emptyDiv";
          newNode.style.background = "transparent";
          newNode.style.overflow = "hidden";
          let _H =
            multiple * pageHeight -
            (lableListID[i].offsetTop + x + lableListID[i].offsetHeight);
          const a = parseInt(Math.floor(_H / 36)) * 36;
          newNode.style.height = _H + "PX";
          newNode.style.width = "100%";
          let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
          // 判断兄弟节点是否存在
          if (next) {
            if (a > 0) {
              newNode.innerHTML = `<p style="height: ${a}PX;overflow:hidden;margin-top:0;text-indent: ${
                getComputedStyle(next, false)["text-indent"]
              };">${next.innerHTML}</p>`;
              console.log(
                next.offsetHeight,
                next.getAttribute("style"),
                getComputedStyle(next, false)["text-indent"]
              );
              next.style = `overflow:hidden;height: ${next.offsetHeight - a}PX;`;
              next.innerHTML = `<p style="margin-top: -${a}PX;text-indent: ${
                getComputedStyle(next, false)["text-indent"]
              };">${next.innerHTML}</p>`;
            }
            // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
            divParent.insertBefore(newNode, next);
          } else {
            // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
            divParent.appendChild(newNode);
          }
        }
      }
    },

    // 判断是否需要添加空白div
    isSplit(nodes, index, pageHeight) {
      let x1 = document.getElementById("itemsss").offsetTop;
      let x = document.getElementById("itemss").offsetTop + x1;
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (
        nodes[index].offsetTop + x + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + x + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true;
      }
      return false;
    },

    cloneBtn1() {
      this.showText = true;
      Toast.loading({
        message: "生成中...",
        forbidClick: true,
        duration:0
      });
      let refsBox = document.querySelector("#pdfDom");
      // this.outPutPdfFn(refsBox)
      let this_ = this;
      html2Canvas(refsBox, {
        useCORS: true,
        scale: 4,
      })
        .then(function (canvas) {
          console.log(canvas, "ccc");
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          let pageHeight = (contentWidth / 592.28) * 841.89;
          let leftHeight = contentHeight;
          let position = 0;
          let imgWidth = 595.28;
          let imgHeight = (592.28 / contentWidth) * contentHeight;
          let pageData = canvas.toDataURL("image/JPEG", 1.0); //压缩的base64，PDF文件
          let PDF = new JsPDF("", "pt", "a4");
          // PDF.setFont("simsun");
          console.log(PDF, "pdf");
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              if (leftHeight > 0) {
                PDF.addPage();
              }
            }
          }
          let pagurl = PDF.output("datauristring");
          this_.pdfURL = pagurl;
          console.log(this_.pdfURL, "3213213");
          let blob = this_.dataURLtoBlob(this_.pdfURL);

          let file = this_.blobToFile(blob, "PDF");
          let that = this_;
          let url = "https://up-z2.qiniup.com";
          let xhr = new XMLHttpRequest();

          xhr.open("POST", url, true);
          let formData = new window.FormData();
          console.log(formData, "3213213");
          formData.append("file", file);
          formData.append("token", that.imgToken);
          formData.append("config", "{ useCdnDomain: true }");
          xhr.send(formData);
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              let link = JSON.parse(xhr.responseText);
              if (link.key == undefined || link.key == null || link.key == "") {
                Toast("错误，请重新生成合同");
              }
              that.imgUrl = `https://cdn.health.healthplatform.xyz/${link.key}`;
              console.log(that.imgUrl);
              that.$emit("submit",that.imgUrl);
            }
          };
        })
        .catch((err) => {
          console.log(err);
          // alert(11111)
          this.$toast("pdf生成失败");
        });
    },
    fildsData(dataurl, filename) {
      //将base64转换为文件
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      this.pdfUgblods(
        new File([u8arr], filename, {
          type: mime,
        })
      );
    },
    fileSize(str) {
      var fileSize;
      if (str.indexOf("=") > 0) {
        var indexOf = str.indexOf("=");
        str = str.substring(0, indexOf);
      }
      fileSize = parseInt(str.length - (str.length / 8) * 2);
      return fileSize;
    },
    //将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    async pdfUgblods(img) {
      let that = this;
      if (img.indexOf("base64") > -1) {
        img = img.split(",")[1];
      }
      let picUrl = "";
      var url = "https://up-z2.qiniup.com/putb64/" + that.fileSize(img);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function (res) {
        console.log(res, xhr, "res");
        if (res.currentTarget.readyState == 4) {
          that.imgUrl =
            "https://cdn.health.healthplatform.xyz/" + JSON.parse(xhr.responseText).key;
          picUrl =
            "https://cdn.health.healthplatform.xyz/" + JSON.parse(xhr.responseText).key;
          that.fileList.push({ url: picUrl });
          console.log(that.imgUrl, "this.imgUrl");
        }
      };
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader("Authorization", "UpToken " + that.imgToken);
      xhr.send(img);
      that.$emit("ruleImgUblod", that.imgUrl);
      // 提交申请

      // that.submitapply();
    },
    submitapply() {
      console.log(this.imgUrl, "this.imgUrl");
      let data = {
        audit_info: {
          application: this.imgUrl,
          nameImg: this.nameImg,
        },
      };

      if (this.status == 1) {
        vipapply_api(data).then((res) => {
          if (res.code == 0) {
            Toast({
              message: "提交成功",
              icon: "success",
              duration: 1000,
            });
            this.$router.push(
              `/expenditure/vipUser/contract?type=1&id=${res.data.audit_id}`
            );
          } else {
            Toast({
              message: res.error,
              icon: "success",
              duration: 1000,
            });
          }
        });
      } else {
        shareapply_api(data).then((res) => {
          if (res.code == 0) {
            Toast({
              message: "提交成功",
              icon: "success",
              duration: 1000,
            });
            this.$router.push(`/expenditure/vipUser/contract?id=${res.data.audit_id}`);
          } else {
            Toast({
              message: res.error,
              duration: 1000,
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
#pdfDom {
  width: 900PX;
  box-sizing: border-box;
  font-size: 21PX;
  color: #000;
  font-family: 'Nsimsun';
}
.page {
  width: 100%;
  /* height: 1275PX; */
  padding: 100PX 70PX;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: 20PX break-word;
}
.infomation_box {
  width: 100%;
  border-radius: 10PX;
}

.from_box {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin: 0 auto;

  box-sizing: border-box;
  text-align: left;
  position: relative;
}

.infomation_box .bgcs {
  opacity: 1;
}

.from_title {
  color: #563b15;
  margin-bottom: 36PX;
  margin-top: 30PX;
  font-weight: bold;
  font-size: 8cqw;
}

.bold {
  display: inline-block;
  margin-bottom: 3PX;
  font-weight: 700;
  flex: 0 0 auto;
}
p {
  margin: 0;
  line-height: 1.5;
}
.cloneBox {
  position: absolute;
  top: 80PX;
  right: 40PX;
  width: 30PX;
  height: 30PX;
  z-index: 2;
}

.title_box {
  width: 660PX;
  /* height:900PX; */
  /* height: 2000PX; */
  border-radius: 10PX 10PX 10PX 10PX;
  opacity: 1;
  /* border: 2PX solid #563b15; */
  font-size: 20PX;
  color: #563b15;
  padding: 21PX;
  box-sizing: border-box;
  line-height: 50PX;
  letter-spacing: 2PX;
  text-align: left;
  padding-bottom: 50PX;
}

.submitBtn_a {
  width: 272PX;
  height: 86PX;
  position: absolute;
  bottom: 400PX;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  background: #d6b06f;
  box-shadow: inset 0PX 4PX 5PX 0PX rgba(255, 255, 255, 0.3);
  border-radius: 50PX 50PX 50PX 50PX;
  opacity: 0.6;
  margin: 47PX auto 0;
  font-size: 30PX;
  color: #fff;
  text-align: center;
  line-height: 86PX;
}

.submitBtn_a1 {
  width: 272PX;
  height: 86PX;
  position: absolute;
  bottom: 300PX;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  background: #d6b06f;
  box-shadow: inset 0PX 4PX 5PX 0PX rgba(255, 255, 255, 0.3);
  border-radius: 50PX 50PX 50PX 50PX;
  opacity: 0.6;
  margin: 47PX auto 0;
  font-size: 30PX;
  color: #fff;
  text-align: center;
  line-height: 86PX;
}

.name_box {
  position: absolute;
  bottom: -30PX;
  right: 90PX;
  display: flex;
  line-height: 100PX;
}

.pos {
  bottom: 550PX;
}

.name_box img {
  width: 50PX;
  height: 100PX;
  transform: rotate(-90deg);
  margin-left: 20PX;
}

.cover2 {
  width: 100%;
  height: 500PX;
  font-size: 25PX;
  margin-top: 30PX;
}

.cover2 p {
  text-align: center;
}

.partitem {
  margin: 150PX 0 0;
}

.part .sub-item {
  padding-left: 20PX;
}

.partitem .cover-data {
  width: 100%;
  height: 30PX;
  margin-top: 5PX;
  text-align: center;
}
.partitem .cover-data:nth-child(1) {
  margin-bottom: 30PX;
}
.cover-data .text {
  display: inline-block;
  width: 250PX;
  border-bottom: 1PX solid #000;
  text-align: center;
}

.data .text {
  flex: 1 1 auto;
  border-bottom: 1PX solid #000;
  text-align: left;
}

.data {
  display: flex;
  margin-top: 5PX;
  font-size: 21PX !important;
}
.mt40 {
  margin-top: 40PX !important;
}
.mt70 {
  margin-top: 70PX !important;
}
.mb30 {
  margin-bottom: 30PX !important;
}
.data .border_none {
  border: none;
}
.page .title-text {
  text-align: center;
  margin-bottom: 20PX;
  margin-top: 100PX;
  font-size: 30PX;
  font-weight: 700;
}
.page .titles-text {
  text-align: center;
  font-size: 20PX;
  margin: 0;
  line-height: 1.5;
}
.part {
  margin: 30PX 0;
}
.zindex {
  word-wrap: break-word;
  text-indent: 30PX;
  margin-top: 10PX;
  font-size: 21PX;
  text-align: justify;
}
.zindexs {
  word-wrap: break-word;
  font-size: 21PX;
  text-align: justify;
}
.word-empty {
  /* width: 60PX; */
  display: inline-block;
  border-bottom: 1PX solid #000;
  padding: 0 10PX;
  text-indent: 0;
  text-align: center;
  box-sizing: border-box;
}
.shareCount {
  padding: 0 10PX;
  border-bottom: 1PX solid black;
  font-size: 21PX;
  font-weight: 700;
}
.datespan {
  margin-top: 40PX;
}
.datespan span {
  width: 100PX;
  margin: 0PX 15PX;
  color: #000;
}
.sign_data {
  display: flex;
  height: 150PX;
}
.sign_data span {
  margin-top: 50PX;
}
.sign_data img {
  transform: rotate(-90deg);
  width: 150PX;
  margin-left: 50PX;
}
.daibiao {
  margin-top: 20PX;
}
.daibiao img:nth-child(2) {
  width: 68PX;
}
.daibiao img:nth-child(3) {
  width: 180PX;
  margin-left: 30PX;
}
.neth_btn_e {
  width: 600PX;
  height: 80PX;
  background: #fe3b22;
  border-radius: 16PX 16PX 16PX 16PX;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28PX;
  color: #ffffff;
  margin: 221PX auto 100PX;
  text-align: center;
  line-height: 80PX;
}

.confim_btn {
  position: fixed;
  bottom: 50PX;
  left: 75PX;
}
</style>
