<template>
    <div class="shipments_item">
        <div class="shipper_box" v-if="dataList.Traces.length!=0">
            <!-- <div class="order" v-if="tableData.activate.shipments.length==1" @click="getOrder">确认收货</div> -->
            <div class="loginBtn1">
                <div style="text-align:right;width:100px;margin-right:30px">物流单号:</div>
                <div>{{dataList.LogisticCode}}</div>
            </div>
            <div class="loginBtn1">
                <div style="text-align:right;width:100px;margin-right:30px;margin-bottom:50px">收件人地址:</div>
                <div>{{address?address:dataList.Location}}</div>
            </div>
            
            <div class="steps1" style="color:#000" v-if="dataList.State==3">
                <div>
                    <div style="margin-left:-10px" class="boxcolor3">收</div>
                    <div class="linecolor1"></div>
                </div>
                <div class="neth">
                    <div style="color:#00000080">[收货地址]{{dataList.Location}}</div>
                </div>
            </div>

            <div class="steps1" v-for="(item,index) in dataList.Traces" v-bind:key="index">
                <div v-if="index!=0">
                    <div class="boxcolor2"></div>
                    <div class="linecolor1"></div>
                </div>
                <div v-else>
                    <div class="boxcolor1"></div>
                    <div class="linecolor1"></div>
                </div>
                <div :class="index==0&&item.Action!=3?'neth colorText':'neth'">
                    <div v-if="item.Action==1">以揽件{{item.AcceptTime}}</div>
                    <div v-if="item.Action==2||item.Action==201">运输中{{item.AcceptTime}}</div>
                    <div v-if="item.Action==202">派送中{{item.AcceptTime}}</div>
                    <div v-if="item.Action==211">待取件{{item.AcceptTime}}</div>
                    <div v-if="item.Action==3">已签收{{item.AcceptTime}}</div>
                    <div>{{item.AcceptStation}}</div>
                </div>
            </div>
            <div class="steps1" v-if="dataList.State==2">
                <div>
                    <div :class="dataList.State==0?'boxcolor1':'boxcolor2'"></div>
                    <div class="linecolor1"></div>
                </div>
                <!-- <div class="neth" :style="dataList.State==0?'color:#000':''">
                    <div>已发货{{tableData.update_time}}</div>
                    <div>等待揽收中</div>
                  </div> -->
            </div>
            <div class="steps1">
                <div>
                    <div class="boxcolor2"></div>
                </div>
                <div class="neth">
                    <div>已下单{{dataList.pay_at}}</div>

                </div>
            </div>
        </div>
        <div class="infoBox" v-if="dataList.Traces.length==0">
            <!-- <img @click="submitBtn" style="width:10px;height:10px;margin-left:300px" src="../../assets/cloneBtn2.png" /> -->
            <div class="auditInfo">物流信息:</div>
            <div class="cart">
                暂无物流信息
            </div>
        </div>
    </div>
</template>
<script>
import { getShipments_api} from "@/api/admin";
export default {
    data(){
        return{
            query:null,dataList:{
                Traces:[]
            },address:''
        }
    },
    created(){
        document.title = "物流信息";
        this.query = this.$route.query;
        let data = JSON.parse(decodeURIComponent(this.query.data));
        if(this.query.address){
            this.address = this.query.address;
            console.log(this.address)
        }
        this.getShipments(data)
    },
    methods:{
        getShipments(data){
            data.logistic = 0
            getShipments_api(data).then(res=>{
                if(res.code==0){
                    console.log(res)
                    this.dataList = res.data;
                    this.dataList.Traces.reverse();
                }
            })
        },
        submitBtn(){

        }

        
    }
};
</script>
<style>
.shipments_item{
    background-color: #fff;
    padding: 30px 20px;
    box-sizing: border-box;
    font-size: 24px;
}
.loginBtn1{
    display: flex;
    font-size:24px;
    margin: 20px 0
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
</style>

