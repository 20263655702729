<template>
    <div class="document_page">
        <new-header :config="headerConfig"></new-header>

        <div class="page_box">
            <div v-if="!imgUrl" class="doc_container-card" ref="imageDom" id="pdfDom2">
                <div class="doc_card">
                    <div class="doc_avator">
                        <img class="doc_avator_img" :src="userData.userImg" crossorigin="anonymous" alt="">
                    </div>
                    <div class="doc_info">
                        <div><span class="doc_info_title">姓名：</span>{{ userData.username }}</div>
                        <div><span class="doc_info_title">性别：</span>{{ userData.sex }}</div>
                        <div><span class="doc_info_title">民族：</span>{{ userData.nation }}</div>
                        <div><span class="doc_info_title">出生年月：</span>{{ userData.birthday }}</div>
                        <div><span class="doc_info_title">政治面貌：</span>{{ userData.politics }}</div>
                        <div><span class="doc_info_title">入会时间：</span>{{ userData.union_auth_at }}</div>
                        <div><span class="doc_info_title">发证单位：</span>{{ '武汉健德生态科技有限公司' }}</div>
                    </div>
                </div>
            </div>
            <img class="doc_url_image" v-else :src="imgUrl" alt="">

            <van-empty class="empty_box" v-if="showTip" description="暂无电子证件，如需要请前往生成" />
        </div>

        <van-overlay :show="Loading" @click="Loading = false">
            <div class="doc_wrapper">
                <van-loading color="#1989fa" />
            </div>
        </van-overlay>

        <div class="doc_btn_div">
            <van-button type="primary" block color="#0091FF" round class="doc_btn" @click="goDocumentForm">
                {{ showTip ? '前往生成' : '重新生成' }}
            </van-button>
        </div>
    </div>
</template>
<script>
import newHeader from "@/components/newHeader";

import html2Canvas from 'html2canvas';

import Cookies from "js-cookie";
import { getUserData_api, changeUserData_api } from '@/api/perdata'
import { getImgToken } from "@/api/common"

export default {
    components: {
        newHeader
    },
    data() {
        return {
            headerConfig: {
                show: true,
                title: '我的电子证件',
            },

            imgUrl: '',
            Loading: false,
            showTip: true,
            userData: {},

            qiniupToken: null,
        }
    },
    created() {
        this.getUser()
        if (Cookies.get("userData")) {
            this.userData = JSON.parse(Cookies.get("userData"))
            Cookies.remove("userData")
            this.getUploadToken()
            this.$nextTick(() => {
                this.clickGeneratePicture()
            })
        }
    },
    methods: {
        // 获取用户电子证件照
        async getUser() {
            let res = await getUserData_api()
            if (res.code == 0) {
                let union_info = res.data.union_info ? res.data.union_info : {}
                if (union_info.userCertificate) {
                    this.showTip = false
                    this.imgUrl = union_info.userCertificate
                } else {
                    this.showTip = true
                }
            }
        },
        //获取七牛云上传token
        async getUploadToken() {
            let res = await getImgToken()
            if (!res || typeof res === "string" || res.error) return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
            this.qiniupToken = res.data;
        },
        // 上传电子证件信息
        async changeUser(userCertificate) {
            let data = this.userData
            // 删除出生年月日和注册时间
            delete data.union_auth_at
            delete data.birthday

            data.userCertificate = userCertificate
            let res = await changeUserData_api(data)
            if (res.code == 0) {
                this.imgUrl = userCertificate
                this.showTip = false
                this.Loading = false
            } else {
                this.Loading = false
                this.$toast(res.error)
            }
        },
        /**
         * 将页面指定节点内容转为图片
         * 1.拿到想要转换为图片的内容节点DOM；
         * 2.转换，拿到转换后的canvas
         * 3.转换为图片
         */
        clickGeneratePicture() {
            this.Loading = true
            window.scroll(0, 0) // 首先先顶部
            const targetDom = this.$refs.imageDom // 获取要截图的元素
            const copyDom = targetDom.cloneNode(true) // 克隆一个
            copyDom.style.width = targetDom.scrollWidth + 'px'
            copyDom.style.height = targetDom.scrollHeight + 'px'
            document.body.appendChild(copyDom) // 添加
            const rect = copyDom.getBoundingClientRect()
            document.body.appendChild(copyDom) // 添加
            html2Canvas(copyDom, {
                x: rect.left,
                y: rect.top,
                useCORS: true,
                width: copyDom.clientWidth,
                height: copyDom.clientHeight,
                scrollY: 0,
                scrollX: 0,
                scale: 3,
                dpi: 300 // 处理模糊问题
            })
                .then((canvas) => {
                    document.body.removeChild(copyDom) // 用完要删除
                    let imgUrl = canvas.toDataURL("image/JPEG");
                    this.putb64(imgUrl, this.qiniupToken)
                }).catch(() => {
                    this.Loading = false
                    this.$toast('图片生成失败')
                })
        },
        // clickGeneratePicture() {
        //     this.Loading = true
        //     let refsBox = document.querySelector("#pdfDom2");
        //     window.scroll(0, 0)
        //     html2Canvas(refsBox, {
        //         allowTaint: true,
        //         useCORS: true,
        //         scrollY: 0,
        //         scrollX: 0,
        //         backgroundColor: "#FFFFFF",
        //         scale: 3,
        //         width: refsBox.clientWidth, //dom 原始宽度
        //         height: refsBox.clientHeight,
        //     })
        //         .then(canvas => {
        //             // 转成图片，生成base64图片地址
        //             let imgUrl = canvas.toDataURL("image/JPEG", 1.0);
        //             this.putb64(imgUrl, this.qiniupToken)
        //         }).catch(() => {
        //             this.Loading = false
        //             this.$toast('图片生成失败')
        //         })
        // },

        /*通过base64编码字符流计算文件流大小函数*/
        fileSize(str) {
            var fileSize;
            if (str.indexOf('=') > 0) {
                var indexOf = str.indexOf('=');
                str = str.substring(0, indexOf); //把末尾的’=‘号去掉
            }
            fileSize = parseInt(str.length - (str.length / 8) * 2);
            return fileSize;
        },
        /*把字符串转换成json*/
        strToJson(str) {
            var json = eval('(' + str + ')');
            return json;
        },
        /*picBase是base64图片带头部的完整编码,myUptoken是从后端返回的upToken*/
        putb64(picBase, myUptoken) {
            /*把头部的data:image/png;base64,去掉。（注意：base64后面的逗号也去掉）*/
            picBase = picBase.substring(23);
            let that = this
            var url = "https://up-z2.qiniup.com/putb64/" + this.fileSize(picBase);
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    var keyText = xhr.responseText;
                    /*返回的key是字符串，需要装换成json*/
                    keyText = that.strToJson(keyText);
                    /* 前面是七牛云空间网址，keyText.key 是返回的图片文件名，这里得到的picUrl就是我们需要的图片地址了*/
                    var picUrl = "https://cdn.health.healthplatform.xyz/" + keyText.key;

                    // 调用信息修改接口
                    that.changeUser(picUrl)
                }
            }
            xhr.open("POST", url, false);
            xhr.setRequestHeader("Content-Type", "application/octet-stream");
            xhr.setRequestHeader("Authorization", "UpToken " + myUptoken);
            xhr.send(picBase);
        },

        goDocumentForm() {
            setTimeout(() => {
                this.$router.push("../document/documentForm")
            }, 500);
        }

    }
}
</script>
<style scoped lang="css">
.document_page {
    box-sizing: border-box;
    background-color: #F9F9FB;
    padding: 35px;
    height: 100vh;
    width: 100%;
}

.doc_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.doc_url_image {
    width: 670px;
}

.page_box {
    height: 570px;
    width: 100%;
}

.doc_container-card {
    height: 560px;
    width: 100%;
}

.doc_card {
    display: flex;
    padding: 42px 0;
    box-sizing: border-box;
    width: 670px;
    height: 560px;
    background-color: #fff;
}

.doc_avator {
    width: 187px;
    height: 255px;
    box-sizing: border-box;
    margin-left: 34px;
    margin-right: 25px;
}

.doc_avator_img {
    width: 187px;
    height: 255px;
}

.doc_info {
    font-size: 22px;
    color: #1A1A1A;
    line-height: 32px;
}

.doc_info>div {
    margin-top: 17px;
    margin-bottom: 30px;
}

.doc_info_title {
    color: #0091FF;
}

.doc_btn_div {
    margin-top: 40px;
}

.doc_btn {
    height: 88px;
}
</style>