<template>
    <div class="login_page">
        <div class="page_back">
            <div class="login_logo">
                <img class="logo_icon" src="../../assets/personalIcon/lidaoLogo.png" alt="">
            </div>
            <div class="login_text">Login</div>
        </div>
        <div class="page_form">
            <div class="form_item">
                <input class="value_input" v-model="form.account" placeholder="请输入购购账号信息" />
            </div>
            <div class="form_item">
                <input class="value_input" v-model="form.code" placeholder="请输入验证码" />
                <div class="item_btn" @click="getCaptcha">
                    <van-count-down class="count" v-if="codeTime" :time="codeTime" format="ss 秒"
                        @finish="codeTime = null" />
                    <span v-else>获取验证码</span>
                </div>
            </div>
            <div class="gg_btn">
                <div @click="onloadGougou">申请购购账号</div>
            </div>
            <div class="login_btn">
                <van-button type="primary" block color="#0091FF" round class="btn" @click="submit">登录</van-button>
            </div>
        </div>

        <new-dialog :config="dialogConfig" @cancel="dialogCancelChange" @confirm="dialogConfirmChange"></new-dialog>
    </div>
</template>
<script>
import newDialog from '../../components/newDialog.vue';

import captcha from '@/utils/captcha';
import valid from '@/utils/valid';
import * as apiCommon from "@/api/common";

import { getMember_api } from "@/api/common";
import Cookies from "js-cookie";

export default {
    components: {
        newDialog,
    },
    data() {
        return {
            form: { account: '', code: '' },
            codeTime: null,
            captcha: null,

            dialogConfig: {
                showDialog: false,
                text: '系统将自动读取：购购"399专区订单"数据信息，如果存在正常订单将自动免费获取一年会员资格，是否要进行企业会员注册，操作后订单将不支持售后服务，请确认。',
                onCancel: true,
                cancelText: '考虑考虑',
                confirmText: '我要注册',
            },
            routeCode: '',
        }
    },
    created() {
        document.title = "利道工会";

        // app跳转当前页面
        if (this.$route.query && this.$route.query.member_id) {
            this.routeCode = this.$route.query.member_id
            this.getMemberLogin(this.$route.query.member_id);
        }
    },
    methods: {
        // 下载购购app
        onloadGougou() {
            window.location.href = "http://qr02.cn/B0Npsm";
        },

        // 获取滑动验证码
        async getCaptchaInfo() {
            let res = await captcha.getCaptcha();
            if (res) {
                this.captcha = res;
                this.timeter()
            }
        },
        // 获取短信验证码
        async timeter() {
            let param = {}, captcha = this.captcha;

            param.mobile = this.form.account;
            param.randstr = captcha.randstr;
            param.ticket = captcha.ticket;

            let res = await apiCommon.getSmsCode(param);
            this.captcha = null;
            this.codeTime = 60 * 1000
            if (!res || typeof res === 'string' || res.error) {
                this.$toast.fail(res ? res.error || res : '获取验证码失败!')
                this.codeTime = null
            }
        },
        getCaptcha() {
            if (!this.form.account) return this.$toast('请先填写手机号');
            if (!valid.isMobile(this.form.account)) return this.$toast('请输入正确的手机号');
            this.getCaptchaInfo();
        },
        // 登录
        async submit() {
            let { account, code } = this.form
            if (!code) return this.$toast('请先填写购购账号和验证码');
            if (account && code) {
                let param = {
                    mobile: account,
                    code: code
                };
                let res = await apiCommon.setjudge(param);
                if (res.code == 0) {
                    this.getMemberToken(res.data.member_id)
                } else {
                    this.$toast(res.error)
                }
            }
        },
        // 弹窗取消
        async dialogCancelChange() {
            this.$router.push("/lador/ladorLogin");
        },
        // 弹窗确认
        async dialogConfirmChange() {
            if (this.routeCode) {
                this.$router.push({ path: '/newLogin/application', query: { code: this.routeCode } })
            } else {
                this.$router.push("/newLogin/application");
            }
        },
        getMemberLogin(member_id) {
            getMember_api(member_id).then((res) => {
                if (res.code == 0) {
                    Cookies.set("token", res.data.Authorization);
                    // this.applyUser()
                    this.$router.push({ path: '/newLogin/application', query: { code: this.routeCode } })
                } else {
                    this.$toast(res.error)
                }
            });
        },
        async applyUser() {
            let res = await apiCommon.getApplyUser();
            if (res.code == 0) {
                if (res.data.user.member_id != 0) { // 绑定了账号但是没有成为会员
                    if (res.data.user.union_member != 0) {
                        // 已经成为会员
                        this.$router.push("/newLogin/applicationSuccess");
                    } else {
                        // 兑换时长成为会员
                        this.$router.push("/newLogin/application");
                    }
                } else {
                    this.$router.push("/lador/index");
                }
            }
        },
        getMemberToken(member_id) {
            getMember_api(member_id).then((res) => {
                if (res.code == 0) {
                    Cookies.set("token", res.data.Authorization);
                    this.applyUser_2();
                } else {
                    this.$toast(res.error)
                }
            });
        },
        async applyUser_2() {
            let res = await apiCommon.getApplyUser();
            if (res.code == 0) {
                if (res.data.user.union_member != 0) {
                    this.$router.push("/newLogin/applicationSuccess");
                } else {
                    this.dialogConfig.showDialog = true
                }
            } else {
                this.$toast(res.error)
            }
        },

    }
}
</script>
<style lang="css" scoped>
.login_page {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #F9F9FB;
    width: 100%;
}

.page_back {
    background: url(../../assets/personalIcon/loginBack.png);
    background-size: cover;
    height: 500px;
    width: 100%;
    position: relative;
}

.login_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
}

.logo_icon {
    width: 180px;
    height: 180px;
}

.login_text {
    height: 180px;
    background-color: #F9F9FB;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 200px 0 0 0;
    text-align: center;
    line-height: 180px;
    font-size: 100px;
    color: #0091FF;
}

.page_form {
    padding: 30px 45px 0 45px;
    box-sizing: border-box;
    background-color: #F9F9FB
}

.form_item {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    height: 88px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.03);
    background: #FFFFFF;
}

.value_input {
    width: 100%;
    height: 60px;
    border: none;
    background-color: inherit;
    font-size: 26px;
}

.item_btn {
    width: 200px;
    padding-left: 20px;
    margin-left: 20px;
    font-size: 26px;
    text-align: center;
    color: #0091FF;
}

.count {
    font-size: 26px;
    color: #0091FF;
}

.gg_btn {
    font-size: 26px;
    margin-bottom: 100px;
    color: #0091FF;
    text-decoration-line: underline;
    text-transform: none;
}

.btn {
    font-size: 32px;
    height: 100px;
}
</style>