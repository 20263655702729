<template>
    <div class="success_page">
        <new-header :config="headerConfig"></new-header>

        <van-empty class="custom-image" :image="successImage">
            <div>
                <div class="text_1">您已成为企业会员</div>
                <div class="text_2">有效期至：{{ exchangeTime }}</div>
            </div>
            <!-- 判断页面进入方式不同，按钮不同 -->
            <div class="btn_div">
                <van-button v-if="btnexchange" class="btn" round color="#0091FF" type="primary" @click="goPage(1)">兑换时长</van-button>
                <van-button v-else class="btn" round color="#0091FF" type="primary" @click="goPage(5)">工分申请</van-button>
            </div>
        </van-empty>

        <div class="list_footer">
            <div class="text_item">用户须知：</div>
            <div class="text_item">1.当您成为会员之后，会员有效授权时间内可享受企业会员权益； </div>
            <div class="text_item">2.可通过"活动订单"免费获赠一年会员，可叠加，可通过个人中心"会员授权"板块进行操作；</div>
            <div class="text_item">3.成为会员后可根据自身实际需要申请会员实物证件，如已办理则无需重复申请，如有需要
                <span class="kefu_text" @click="goPage(2)">联系客服</span>处理；
            </div>
            <div class="text_item">4.可申请电子证件，与实物同等<span class="documents_text" @click="goPage(3)">我的电子证件 ></span></div>
        </div>

        <div class="goback_div" @click="goPage(4)">
            <van-icon class="goback_icon" :name="gobackIcon" /><span>回到个人中心</span>
        </div>

    </div>
</template>
<script>
import successImage from '@/assets/personalIcon/listSuccess.png'
import gobackIcon from '@/assets/personalIcon/gobackIcon.png'
import newHeader from "@/components/newHeader";

import { getuser_api } from "@/api/common";

export default {
    components: {
        newHeader
    },
    data() {
        return {
            successImage: '',
            gobackIcon: '',

            headerConfig: {
                show: true,
                title: '我的会员',
            },
            btnexchange: true,
            exchangeTime: 'XXXX-XX-XX xx:xx:xx'
        }
    },
    created() {
        this.successImage = successImage
        this.gobackIcon = gobackIcon

        // app流程走当前页面
        if (this.$route.query && this.$route.query.code) {
            this.btnexchange = false
        }else{
            this.btnexchange = true
        }

        this.getUser()
    },
    methods: {
        // 获取会员信息
        async getUser() {
            let res = await getuser_api()
            if (res.code == 0) {
                this.exchangeTime = res.data.union_expire_at
            }
        },
        goPage(status) {
            if (status == 1) {
                this.$router.push("../newLogin/exchangeTime") // 兑换时长
            }
            if (status == 2) {
                // this.$router.push("../lador/service"); // 联系客服
                window.location.href = "https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000045676&chatId=176634cd-cb09-4d26-89e6-86a702354bcf";
            }
            if (status == 3) {
                this.$router.push("../document/index") // 我的电子证件
            }
            if (status == 4) {
                this.$router.push("../lador/ladorLogin"); // 回到个人中心
            }
            if (status == 5) {
                this.$router.push("../workpoints/replacement"); // 工分申请
            }
        },
    }
}
</script>
<style lang="css" scoped>
.success_page {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    padding: 0 47px;
}

.custom-image {
    min-height: 850px;
}

.custom-image>>>.van-empty__image {
    width: 196px;
    height: 196px;
}

.custom-image>>>.van-empty__description {
    width: 480px;
    text-align: center;
}

.text_1,
.text_2 {
    text-align: center;
    font-size: 28px;
    line-height: 40px;
    color: rgba(40, 40, 40, 0.8);
}

.text_2 {
    color: #0091FF;
    letter-spacing: 1px;
}

.btn_div {
    text-align: center;
    font-size: 32px;
    margin-top: 35px;
}

.btn {
    width: 320px;
    height: 88px;
}

.list_footer {
    padding: 0 10px;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 30px;
    line-height: 40px;
    text-align: left;
    margin-bottom: 20px;
}

.goback_div {
    line-height: 33px;
    text-align: center;
    color: #0091FF;
    font-size: 28px;
}

.goback_icon {
    vertical-align: -3px;
    margin-right: 10px;
}

.kefu_text {
    color: #0091FF;
    text-decoration-line: underline;
}

.documents_text,
.jt_icon {
    font-size: 26px;
    color: #0091FF;
}
</style>