<template>
  <div class="formList">
    <div v-if="isNumber">
      <!-- <div @click="uploadBtn" v-if="fileList.length == 0" :class="uploadData.num == 1 ? 'uplodImg1' : 'uplodImg'">上传图片
      </div>
      <div v-if="fileList.length > 0" style="display:flex;flex-wrap: wrap;height:100%;width:100%">
        <div class="imgClass" v-for="(item, index) in fileList" :key="index">
          <img :src="item.url" />
          <div class="box_clone" @click="imgBtnClone(1, index)">
            <img class="imgs" src="../assets/cloneBtn2.png" />
          </div>
        </div>
      </div> -->

      <!-- <div class="img_title" style="margin-right:5px">{{ uploadData.title }}</div> -->
      <van-uploader class="imgFlexd" :max-count="1" accept="image/*" v-model="fileList" upload-text="上传图片"
        :before-read="beforeReadOne" :after-read="e => afterRead(e, 'imgData')" />
      <!-- <div v-if="uploadData.num != 1" style="color:#fff;font-size:14px;margin-top:10px;margin-left:10px">*最多十张</div> -->
    </div>

    <div v-if="isAudit">
      <van-uploader class="imgFlexd" :max-count="1" accept="image/*" v-model="fileList" :upload-text="uploadData.text"
        :before-read="beforeReadOne" :after-read="e => afterRead(e, 'imgData')" />
    </div>


    <div v-else-if="isUser == true">
      <div @click="uploadBtn" v-if="fileList.length == 0" class='uplodImg1'>上传图片</div>
      <div v-if="fileList.length > 0" style="display:flex;flex-wrap: wrap;height:100%;width:100%">
        <div class="imgClass" v-for="(item, index) in fileList" :key="index">
          <img :src="item.url" />
          <div class="box_clone" @click="imgBtnClone(1, index)">
            <img class="imgs" src="../assets/cloneBtn2.png" />
          </div>
        </div>
      </div>

    </div>
    <div v-else-if="idCard == true">
      <div @click="uploadBtn" v-if="fileList.length == 0" class="uplodImg2">上传图片</div>
      <div v-if="fileList.length > 0" style="display:flex;flex-wrap: wrap;height:100%;width:100%">
        <div class="imgClass" v-for="(item, index) in fileList" :key="index">
          <img :src="item.url" />
          <div class="box_clone" @click="imgBtnClone(1, index)">
            <img class="imgs" src="../assets/cloneBtn2.png" />
          </div>
        </div>
      </div>

    </div>

    <!-- 物料变更 -->
    <div v-else-if="isAlteration == true" style="display:flex">
      <div class="img_title" style="margin-right:5px">{{ uploadData.title }}</div>
      <van-uploader class="imgFlexd" :max-count="1" accept="image/*" v-model="uploadData.value" upload-text="点击上传"
        :before-read="beforeReadOne" :after-read="e => afterRead(e, 'imgData')" />
      <!-- <div @click="uploadBtn" v-if="fileList.length==0&&uploadData.value.length==0" class='uplodImg3' style="border:1px solid #eee">上传图片</div> -->
      <!-- <van-uploader v-on:oversize="oversize" v-model="uploadData.value" class="imgFlexd" accept="image/*" multiple
        :before-read="beforeReadOne" :after-read="e => afterRead(e)" :max-count="1" /> -->

      <!-- <div v-if="fileList.length > 0 || uploadData.value.length > 0" class="i_class_img">
        <div class="imgClass" v-for="(item, index) in uploadData.value" :key="index">
          <img :src="item.url" @click="lookIcon(item.url)" />
          <div class="box_clone" @click="imgBtnClone(2, index)">
            <img class="imgs" src="../assets/cloneBtn2.png" />
          </div>
        </div>
      </div> -->

    </div>

    <!-- 签名 -->
    <div v-else-if="isNameShow == true" style="display:flex">
      <div class="img_title">{{ uploadData.title }}</div>
      <div @click="uploadBtn" v-if="fileList.length == 0 && uploadData.value.length == 0" class='uplodImg3'
        style="border:1px solid #eee">上传图片</div>
      <div v-if="fileList.length > 0 || uploadData.value.length > 0" class="i_class_img">
        <div class="imgClass" v-for="(item, index) in uploadData.value" :key="index">
          <img :src="item.url" @click="lookIcon(item.url)" />
          <div class="box_clone" @click="imgBtnClone(2, index)">
            <img class="imgs" src="../assets/cloneBtn2.png" />
          </div>
        </div>
      </div>

    </div>

    <div v-else>

      <van-uploader v-on:oversize="oversize" class="imgFlexd" accept="image/*" v-if="fileList.length < 3" multiple
        :before-read="beforeRead" :max-count="1" />
      <div v-if="fileList.length > 0" style="display:flex">
        <div class="imgClass" v-for="(item, index) in fileList" :key="index">
          <img :src="item.url" />
          <div class="box_clone" @click="imgBtnClone(1, index)">
            <img class="imgs" src="../assets/cloneBtn2.png" />
          </div>
        </div>
      </div>
      <div style="color:#fff;font-size:14px;margin-top:10px;margin-left:10px">*最多三张</div>
    </div>
    <div v-if="loadingshow">
      <van-loading size="40px" text-size="20px" text-color="#0094ff" color="#0094ff" vertical>上传中...</van-loading>
    </div>

  </div>
</template>
<script>
import * as apiCommon from "@/api/common";
import { ImagePreview } from "vant";
import wx from "weixin-js-sdk";

// import axios from "axios";
export default {
  data() {
    return {
      fileList: [],
      loadingshow: false,

    };
  },
  props: {
    // 物料变更图片标签
    number: {
      type: Number
    },
    uploadData: {
      type: Object
    },
    isNumber: {
      type: Boolean
    },
    isUser: {
      type: Boolean
    },
    idCard: {
      type: Boolean
    }, isAlteration: {
      type: Boolean
    }
    , isNameShow: {
      type: Boolean
    },
    isAudit:{
      type: Boolean
    }
  },
  created() {
    this.getToken();
    // this.fileList = this.uploadData.value
  },
  methods: {
    // 查看合同
    lookIcon(img) {
      console.log(img);
      ImagePreview([img]);
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.uploadData.imgToken = res.data;
    },
    async uploadBtn() {
      let url = location.href.split("#")[0];
      let res1 = await apiCommon.getConfig({ url: url });
      let tableData = res1.data;
      tableData.url = encodeURIComponent(tableData.url + "#/alteration/Information");

      tableData.jsApiList.push("getLocalImgData");
      tableData.jsApiList.push("uploadFile");
      wx.config({
        debug: false, //生产环境需要关闭debug模式
        appId: tableData.appId, //appId通过微信服务号后台查看
        timestamp: tableData.timestamp, //生成签名的时间戳
        nonceStr: tableData.nonceStr, //生成签名的随机字符串
        signature: tableData.signature, //签名
        jsApiList: tableData.jsApiList,
        url: tableData.url
      });
      let that = this;
      if (res1.code == 0) {
        wx.chooseImage({
          succress: function (res) {
            console.log(res);
          },
          fail: function (res) {
            that.loadingshow = false;
            // console.log("fail");
            console.log(res);
          },

          complete: function (res) {
            if (res.errMsg == "chooseImage:ok") {
              that.loadingshow = true;
              that.imgData(res);
            } else if (res.errMsg == "chooseImage:cancel") {
              that.loadingshow = false
            } else {
              // Toast("获取图片失败，请重新选择");
              console.log(1)
            }
          }
        });
      }
    },
    async imgData(res) {
      for (var i = 0; i < res.localIds.length; i++) {
        console.log(i);
        var localId = res.localIds[i];
        await this.mostImg(localId);
      }
    },
    mostImg(item) {
      let that = this;
      return new Promise(resolve => {
        wx.getLocalImgData({
          localId: item,
          succress: function (res) {
            console.log(res);
          },
          complete: function (res) {
            let localData = res.localData;
            that.upload(localData);
            resolve("done!");
          },
          fail: function () {
            that.$toast.clear();
            that.loadingshow = false;
          }
        });
      });
    },
    fildsData(dataurl, filename) {
      //将base64转换为文件
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      this.upload(
        new File([u8arr], filename, {
          type: mime
        })
      );
    },
    imgBtn(e) {
      console.log(e, "312321");
    },
    goClone() {
      this.$router.go(-1);
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../views/lador/service");
    },
    imgBtnClone(index, id) {
      // console.log(index, "11");
      if (index == 2) {
        this.uploadData.value.splice(id, 1)
      } else {
        this.fileList.splice(id, 1);
      }

    },
    oversize() { },
    beforeRead(data) {
      console.log(data, "data")
      let e = data.target.files;
      if (e.length > 1) {
        for (var i = 0; i < e.length; i++) {
          this.upload(e[i]);
        }
      } else {
        let img = e[0];
        this.upload(img);
      }
    },
    beforeReadOne(e) {
      if (e.size > 10 * 1024 * 1024) {
        this.$toast('文件大小不能超过10M')
        return false
      }
      return true
    },
    afterRead(file) {
      // alert(file.content)
      this.upload(file.file)
      // console.log(file);
      // let param = new FormData();
      // param.append("token", this.uploadData.imgToken);
      // param.append("config", this.uploadData.config);
      // param.append("file", file);

      // let res = await apiCommon.uploadImg(param);
      // let imgUrl = "https://cdn.health.healthplatform.xyz/" + res.key;
      // this.fileList = [{ url: imgUrl }]
      // this.uploadData.value = [{ url: imgUrl }]
      // this.$emit("uploadImg", this.fileList, this.number);
    },
    fileSize(str) {
      var fileSize;
      if (str.indexOf("=") > 0) {
        var indexOf = str.indexOf("=");
        str = str.substring(0, indexOf);
      }
      fileSize = parseInt(str.length - (str.length / 8) * 2);
      return fileSize;
    },
    async upload(file) {
      // let that = this;
      // if (img.indexOf("base64") > -1) {
      //   img = img.split(",")[1];
      // }
      // let picUrl = "";
      // var url = "https://up-z2.qiniup.com/putb64/" + that.fileSize(img);
      // var xhr = new XMLHttpRequest();
      // xhr.onreadystatechange = function (res) {
      //   if (res.currentTarget.readyState == 4) {
      //     picUrl = "https://cdn.health.healthplatform.xyz/" + JSON.parse(xhr.responseText).key;
      //     that.fileList.push({ url: picUrl ,isImage: true});
      //   }
      // };
      // xhr.open("POST", url, true);
      // xhr.setRequestHeader("Content-Type", "application/octet-stream");
      // xhr.setRequestHeader("Authorization", "UpToken " + that.uploadData.imgToken);
      // xhr.send(img);
      // that.loadingshow = false;

      let param = new FormData();
      param.append("token", this.uploadData.imgToken);
      param.append("config", this.uploadData.config);
      param.append("file", file);

      let res = await apiCommon.uploadImg(param);
      let imgUrl = "https://cdn.health.healthplatform.xyz/" + res.key;
      this.fileList = [{ url: imgUrl, isImage: true }]
      console.log(this.fileList, "this.fileList")
      // this.uploadData.value = [{ url: imgUrl,isImage: true }]
      this.$emit("uploadImg", this.fileList, this.number);
    }
  }
};
</script>
<style type="text/css" scoped>
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}

.messageTitle3 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}

.messageTitle3 {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 20px;
  color: #333;
}

.formList {
  display: flex;
  font-size: 40px;
  font-weight: 800;
  width: 100%;
  height: 100%
}

.van-uploader__upload {
  width: 162px;
  height: 162px;
  background: #f1f8ff;
  border: 1px solid #63b0ff;
  opacity: 1;
  border-radius: 5px;
}

textarea {
  width: 477px;
  height: 195px;
  background: #ffffff;
  border: 2px solid #9b9b9b;
  opacity: 0.95;
  border-radius: 5px;
  margin: 41px 0 0 50px;
}

.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}

.imgClass {
  position: relative;
  display: flex;

}

.imgClass img {
  width: 120px;
  height: 120px;
  margin: 0 10px;
}

.Img1 {
  width: 100px;
  height: 100px;
  margin: 0 10px;
}

.box_clone {
  width: 20px;
  /* height:20px; */
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.imgFlexd {}

.box_clone .imgs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

input {
  width: 100px;
  height: 100px;
  color: #fff;
  font-size: 30px;
}

.uplodImg {
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 200px;
  font-size: 25px;
  margin-bottom: 20px;
  border: 1px solid #eee;
}

.uplodImg1 {
  width: 174px;
  height: 171px;
  text-align: center;
  line-height: 200px;
  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 40px
}

.uplodImg3 {
  width: 180px;
  height: 140px;
  text-align: center;
  line-height: 120px;
  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 0px;
  margin-left: 15px
}

.uplodImg2 {
  width: 417px;
  height: 247px;
  text-align: center;
  line-height: 200px;
  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 40px;
  color: #fff
}

.i_class_img {
  display: flex;
  flex-wrap: wrap;
  height: 140px;
}

.img_title {
  width: 170px;
  font-size: 30px;
  color: #1A1A1A;
  font-weight: 500;
  text-align: right;
}
</style>
